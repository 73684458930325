import { CommentOutlined, EllipsisOutlined } from "@ant-design/icons";
import { useChatContext } from "../context/ChatContext";

function MessagePreview({text,onClick}){
    const {theme} = useChatContext();    

    return(
        <div onClick={onClick} className="w-full text-left font-medium p-2 relative" style={{color: theme?.colors?.tertiary1 ? theme?.colors?.tertiary1 : '#1b1b1b'}}>
            <div className="flex items-center gap-1 text-ellipsis">
                <div >
                    <CommentOutlined/>
                </div>
                <div>
                    {text}
                </div>
            </div>
            <div className="absolute top-0 left-0 right-0 bottom-0 p-2 flex items-center duration-100 opacity-0 hover:opacity-100 bg-hover rounded-lg cursor-pointer">
                <div className="flex-1"/>
                <div>
                    <EllipsisOutlined />
                </div>
            </div>
        </div>
    )
}

export default MessagePreview;