import { CommentOutlined } from "@ant-design/icons";


function Navbar({onClick}) {
    return(
        <div className="fixed top-2 md:top-10 left-2 md:left-10 right-2 md:right-10 z-10 flex items-center border bg-white p-2 pl-6 pr-8 rounded-full">
            <div className="flex-1 flex items-center gap-2">
                <div className="text-xl">
                    <a href='/' >
                        <CommentOutlined/>
                    </a>
                </div>
                <div onClick={onClick} className="p-3 no-mobile rounded-md text-base font-medium text-[#1f1f1f] cursor-pointer duration-150 hover:bg-[#f0f0f0]">
                    For Creators
                </div>
                <div onClick={onClick} className="p-3 no-mobile rounded-md text-base font-medium text-[#1f1f1f] cursor-pointer duration-150 hover:bg-[#f0f0f0]">
                    For Business
                </div>
            </div>
            <div className="flex items-center gap-2">
                <div onClick={onClick} className="px-4 md:px-6 py-2 md:py-4 rounded-md bg-[#f0f0f0] text-base font-semibold cursor-pointer">
                    Log in
                </div>
                <div onClick={onClick} className="px-4 md:px-6 py-2 md:py-4 rounded-md bg-[#1f1f1f] text-base text-white cursor-pointer font-semibold rounded-full">
                    Sign up free
                </div>
            </div>
        </div>
    )
}

export default Navbar;