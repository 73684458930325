import { Collapse, ConfigProvider } from "antd";
import { useState } from "react";

const items = [
    {
        key: '0',
        label: 'Why do I need a Talk In Bio?',
        children: <div>
                <div>
                    TalkInBio helps <strong>your audience feel more connected with you</strong> than ever before.
                </div>
                <div>
                    It can be hard to have meaningful interactions with every follower but with TalkInBio we help you create personalized experiences, conversations, and recommendations so your followers feel like they are <strong>talking to you 1-on-1</strong>. 
                </div>
            </div>,
    },
    {
        key: '1',
        label: 'What is the TalkInBio service?',
        children: <p>
            TalkInBio allows influencers and businesses to <strong>create personalized chatbots that can engage with their audience 24/7</strong>. Instead of just placing links in your bio, your audience can talk to a Virtual You that answers FAQs, recommends products, promotes services, and interacts directly with your audience. <strong>No one-size-fits-all. Give each follower a personalized experience</strong> and build a deeper connection.
        </p>,
    },
    {
        key: '2',
        label: 'How do I create a chatbot?',
        children: <div>
            <p className="mb-2">
                Creating a chatbot with TalkInBio is <strong>simple and requires no coding skills</strong>:
            </p>
            <ul className="list-decimal pl-4">
                <li><strong>Sign Up</strong>: Create your account and connect your social media profiles.</li>
                <li><strong>Customize</strong>: Personalize your chatbot's tone, responses, and appearance.</li>
                <li><strong>Share</strong>: Add the TalkInBio link to your bio on Instagram, TikTok, YouTube, or any platform and start engaging your followers instantly.</li>
            </ul>
        </div>,
    },
    {
        key: '3',
        label: 'Do I need coding experience to set this up?',
        children: <p>No! TalkInBio is designed to be <strong>easy to use for everyone</strong>. You can set up your chatbot with just a few clicks. All you need to do is customize the responses and add your preferences, and we'll handle the technical side for you.</p>,
    },
    {
        key: '4',
        label: 'How does the chatbot learn my content?',
        children: <p>Our platform allows you to <strong>connect to your existing social media content, YouTube videos, blog posts, or other materials</strong>. You can also manually add responses to specific FAQs or common inquiries your audience may have. This way, <strong>your chatbot reflects your unique voice and content</strong>.</p>,
    },
    {
        key: '5',
        label: 'What can my chatbot do for my audience?',
        children: <div>
            <p className="mb-2">Your chatbot can:</p>
            <ul className="list-disc pl-4">
                <li><strong>Answer frequently asked questions (FAQs)</strong> about your content or products.</li>
                <li><strong>Recommend products or services</strong>, including affiliate links.</li>
                <li><strong>Provide personalized advice</strong> based on user queries (e.g., fitness tips, beauty routines, etc.).</li>
                <li><strong>Send updates</strong> about new content, events, or launches.</li>
                <li><strong>Capture leads</strong> like email sign-ups for newsletters or exclusive offers.</li>                
            </ul>
            </div>,
    },
    {
        key: '6',
        label: 'Can I use this chatbot across different social media platforms?',
        children: <p >Yes! Your chatbot is designed to be versatile and works across platforms like Instagram, TikTok, YouTube, and even your personal website. Just place the chat link in your bio, and your audience can start interacting wherever they find you.</p>,
    },
    {
        key: '7',
        label: "Can I customize the chatbot's appearance and personality?",
        children: <p>Absolutely! You can choose how your chatbot speaks to your audience, customize its tone and style, and personalize its visual appearance to match your brand. From friendly and casual to professional and informative, your chatbot will reflect your brand’s identity.</p>,
    },
    {
        key: '8',
        label: 'Is it free?',
        children: <p>TalkInBio is free for the first 100 users. We will be adding new features in the future which may require a subscription.</p>,
    },
    {
        key: '9',
        label: 'How can I monetize my chatbot?',
        children: <div>
            <p className="mb-2">You can monetize your chatbot in multiple ways, such as:</p>
            <ul className="list-disc pl-4">
                <li>Affiliate Marketing: Include affiliate links in your chatbot's responses and recommend products directly to your audience.</li>
                <li>Direct Sales: If you sell products or services, your chatbot can promote these and direct users to your store or booking page.</li>
                <li>Sponsored Content: You can feature sponsored products or promotions directly in your chatbot's responses.</li>
            </ul>
        </div>,
    },
    {
        key: '10',
        label: 'How do I track the performance of my chatbot?',
        children: <p>TalkInBio provides in-depth analytics so you can track how your chatbot is performing. You can see:

        Number of interactions
        Common questions and popular responses
        Conversion rates (e.g., how many users clicked affiliate links or made purchases)
        Engagement levels over time
        This helps you optimize your chatbot and grow your audience engagement.
        
        </p>,
    },
    {
        key: '11',
        label: 'Can I use the chatbot to capture leads?',
        children: <p>Yes! Your chatbot can be customized to capture user information such as email addresses for newsletters, webinar sign-ups, or special offers. This makes it a powerful tool for building your mailing list or collecting customer details for follow-ups.</p>,
    },
    {
        key: '12',
        label: 'What kind of customer support do you offer?',
        children: <div>
                <p>We offer a comprehensive support system, including:</p>
                <ul className="list-disc pl-4">
                    Live Chat Support: Available within the platform for any questions or technical issues.
                    Email Support: Reach out to our support team anytime.
                    Help Center: Access a library of tutorials and guides to help you get the most out of your chatbot.
                </ul>
            </div>,
    },
    {
        key: '13',
        label: 'Can I change my chatbot’s settings after it’s live?',
        children: <p>Yes! You can easily update your chatbot’s responses, appearance, and settings anytime. Simply log in to your TalkInBio dashboard, make the changes, and they will be reflected immediately.</p>,
    },
    {
        key: '14',
        label: 'Is the chatbot available in multiple languages?',
        children: <p>Yes, TalkInBio supports multilingual capabilities. You can create chatbots in different languages, making it easy to engage with a global audience.</p>,
    },
    {
        key: '15',
        label: 'Is my data safe with TalkInBio?',
        children: <p>We take data privacy and security seriously.</p>,
    },
];
function QuestionCollapse({header,text}){
    return (
        <Collapse className="bg-white px-1 md:px-4 py-2 rounded-3xl m-auto w-full" expandIconPosition='end'size='large' bordered={false}>
            <Collapse.Panel 
                header={<div className="pl-2 leading-snug md:leading-normal">{header}</div>}
                style={{fontSize:'20px'}}
                className="font-semibold text-left p-0 md:p-4"
                key="1">
                <div className="py-2 text-lg font-normal">
                    {text}
                </div>
            </Collapse.Panel>
        </Collapse>
    )
}

  
function FAQCollapse(){
    const [showFull,setShowFull] = useState(false);

    return(
        <ConfigProvider
            theme={{
                components: {
                    Collapse: {
                        contentBg:'red',
                    },
                },
            }}>
            <div className="flex flex-col gap-4 w-full">
                {
                    showFull
                    ?   items.map((value,index) => 
                            <div key={`q-${index}`}>
                                <QuestionCollapse header={value['label']} text={value['children']} />
                            </div>
                        )
                    :   items.slice(0,8).map((value,index) => 
                            <div key={`q-${index}`}>
                                <QuestionCollapse header={value['label']} text={value['children']} />
                            </div>
                        )
                }
                <div className="mt-4 cursor-pointer font-bold text-base" onClick={() => setShowFull(prev => !prev)}>
                    {showFull ? 'Show Less' : 'Show More Questions'}
                </div>
            </div>
        </ConfigProvider>
    )
    
}

export default FAQCollapse;