import axios from "axios";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;

class AppServices {

    getApp({path}){
        //
        const values = {
            'path':path
        }

        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/application`,
            data: values
        };
        
        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }

    getOvApp({sk}){
        //
        const values = {
            'sk':sk
        }

        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/query/app`,
            data: values
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
}
export default new AppServices();