import { Button } from "antd";



function NicheFlipCard({name,image,ideas,onClick}){

    return(
        <div class="flip-card">
            <div class="flip-card-inner">
                <div class="flip-card-front">
                <div className="w-full h-full relative flex items-center justify-center rounded-lg border border-[#FF4081] shadow-lg" style={{background:`linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.1)), url(${image})`,backgroundPosition:'center',backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
                        <div className="text-white text-3xl font-semibold" >
                            {name}
                        </div>
                    </div>
                </div>
                <div class="flip-card-back">
                    <div className="w-full h-full relative flex items-center justify-center rounded-lg shadow-lg" style={{background:`linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${image})`,backgroundPosition:'center',backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
                        <div className="text-left text-white pb-8 flex flex-col gap-2">
                            <div className="text-2xl md:text-3xl font-semibold" >
                                {name}
                            </div>
                            <ul className="text-sm md:text-base list-disc pl-4 text-lg">
                                {
                                    ideas.map((value,index) => 
                                        <li key={`item-${name}-${index}`}>
                                            {value}
                                        </li>
                                    )
                                }
                            </ul>
                            <div>
                                <Button type="primary" onClick={onClick} size='small' className="bg-[#FF4081] rounded-full text-white font-medium" >Get Started</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NicheFlipCard;