import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import { Layout } from "antd";
import { Suspense } from "react";
import "antd/dist/reset.css";
import './output.css';
import './style/index.css';
import HomePage from "./containers/landing/HomePage";
import LoadingPage from "./containers/landing/LoadingPage";
import ChatPage from "./containers/chat/containers/ChatPage";
import Error404Page from "./containers/landing/Error404Page";
import { ChatProvider } from "./containers/chat/components/context/ChatContext";
import { AuthProvider } from "./containers/utils/useAuth";


const { Content } = Layout;

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Layout>
            <Content>
              <Routes>
                <Route path='/' element={<Suspense fallback={<LoadingPage />} ><HomePage /></Suspense>} />
                <Route path='/404' element={<Suspense fallback={<LoadingPage />} ><Error404Page /></Suspense>} />
                <Route path='/:username' element={<Suspense fallback={<LoadingPage />} ><ChatProvider><ChatPage /></ChatProvider></Suspense>} />
              </Routes>
            </Content>
          </Layout>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
