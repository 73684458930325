import { CommentOutlined, FacebookOutlined, LinkedinOutlined, TwitchOutlined, YoutubeOutlined } from "@ant-design/icons";


function Footer(){
    return(
        <div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 md:gap-2 text-black text-left mb-16">
                <div>
                    <div className="mb-4">
                        <h3 className="font-bold text-2xl text-[#262626]">Company</h3>
                    </div>
                    <div className="flex flex-col gap-3 text-sm text-[#8c8c8c]">
                        <div>
                            TalkInBio Blog
                        </div>
                        <div>
                            What's New
                        </div>
                        <div>
                            Career
                        </div>
                        <div>
                            About
                        </div>
                        <div className="cursor-pointer">
                            <a href='mailto:daiki@onlyvectors.com'>
                                Contact
                            </a>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="mb-4">
                        <h3 className="font-bold text-2xl text-[#262626]">Support</h3>
                    </div>
                    <div className="flex flex-col gap-3 text-sm text-[#8c8c8c]">
                        <div>
                            Getting Started
                        </div>
                        <div>
                            How-To Guides
                        </div>
                        <div>
                            FAQs
                        </div>
                        <div>
                            Report a Violation
                        </div>
                    </div>
                </div>
                <div>
                    <div className="mb-4">
                        <h3 className="font-bold text-2xl text-[#262626]">Trust & Legal</h3>
                    </div>
                    <div className="flex flex-col gap-3 text-sm text-[#8c8c8c]">
                        <div>
                            Terms & Conditions
                        </div>
                        <div>
                            Privacy Notice
                        </div>
                    </div>
                </div>
                <div>
                    <div className="mb-4">
                        <h3 className="font-bold text-2xl text-[#262626]">TalkInBio <CommentOutlined/></h3>
                    </div>
                    <div>
                        <div className="text-sm text-[#8c8c8c]">
                            ©TalkInBio2024
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-start md:items-end flex-col md:flex-row gap-8">
                <div className="flex-1 flex items-center gap-2">
                    <div className="px-4 md:px-6 py-2 md:py-4 rounded-md bg-[#f0f0f0] text-base text-black font-semibold cursor-pointer">
                        Log in
                    </div>
                    <div className="px-4 md:px-6 py-2 md:py-4 rounded-md bg-[#1f1f1f] text-base text-white cursor-pointer font-semibold rounded-full">
                        Sign up free
                    </div>
                </div>
                <div className="flex items-center gap-4">
                    <div className="text-2xl md:text-4xl text-black font-semibold cursor-pointer">
                        <FacebookOutlined/>
                    </div>
                    <div className="text-2xl md:text-4xl text-black font-semibold cursor-pointer">
                        <TwitchOutlined/>
                    </div>
                    <div className="text-2xl md:text-4xl text-black font-semibold cursor-pointer">
                        <LinkedinOutlined/>
                    </div>
                    <div className="text-2xl md:text-4xl text-black font-semibold cursor-pointer">
                        <YoutubeOutlined/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;