import Sider from "antd/es/layout/Sider";
import { useChatContext } from "../context/ChatContext";
import { useEffect } from "react";
import { FormOutlined, LayoutOutlined, LoadingOutlined, MenuOutlined } from "@ant-design/icons";
import { ConfigProvider, Drawer, message, Popover } from "antd";
import MessagePreview from "./MessagePreview";
import MessageActions from "./MessageActions";

function ChatSiderContent({setIsSiderVisible, app}){
    const clickDemo = () => {
        message.info('This feature is not available in demo.');
    }

    return(
        <div className="w-full h-full bg-cover bg-center bg-no-repeat relative" style={app?.theme?.sider_image ? {backgroundImage:`url("${app?.theme?.sider_image}")`} : {}}>
            <div className="absolute top-0 left-0 right-0 bottom-0 p-2 flex flex-col" style={{backgroundColor: app?.theme?.sider_image ? app?.theme?.colors?.secondary ? `${app?.theme?.colors?.secondary}F5` : 'rgba(0,0,0,0.4)' : app?.theme?.colors?.secondary ? app?.theme?.colors?.secondary : '#1b1b1b'}}>
                <div className="flex items-center">
                    <div className="flex-1">
                        <Popover content={'Close sidebar'} placement="right" trigger={'hover'}>
                            <div onClick={() => setIsSiderVisible(prev => !prev)} className="text-2xl md:text-lg cursor-pointer w-fit rounded-xl aspect-square px-2 bg-hover duration-150 flex items-center justify-center" style={{color: app?.theme?.colors?.tertiary1 ? app?.theme?.colors?.tertiary1 : '#1b1b1b'}}>
                                <LayoutOutlined />
                            </div>
                        </Popover>
                    </div>
                    <div>
                        <Popover content={'New chat'} trigger={'hover'}>
                            <div onClick={clickDemo} className="text-2xl md:text-lg w-fit rounded-xl aspect-square px-2 bg-hover duration-150 cursor-pointer flex items-center justify-center" style={{color: app?.theme?.colors?.tertiary1 ? app?.theme?.colors?.tertiary1 : '#1b1b1b'}}>
                                <FormOutlined />
                            </div>
                        </Popover>
                    </div>
                </div>
                <div className="flex-1">
                    <div className="h-full w-full overflow-y-auto py-4 flex flex-col gap-4 text-lg md:text-sm">
                        <div className="flex flex-col">
                            <div className="text-left p-2 text-sm md:text-xs font-bold underline" style={{color: app?.theme?.colors?.tertiary1 ? app?.theme?.colors?.tertiary1 : '#1b1b1b'}}>
                                Agents
                            </div>
                            <div className="flex flex-col gap-0.5">
                                {
                                    app
                                    ?
                                        app?.agent_temp && app?.agent_temp?.agents
                                        ?
                                            app?.agent_temp?.agents.map((value,index) => 
                                                <div key={`agent-${index}`}>
                                                    <MessageActions text={value} onClick={clickDemo}/>
                                                </div>
                                            )
                                        :   <MessageActions text={'New Chat'} onClick={clickDemo}/>
                                    :   <div className="rounded-xl flex items-center" style={{backgroundColor:'rgba(255,255,255,0.2)'}}>
                                            <MessageActions text={'Loading...'} onClick={clickDemo}/>
                                            <div className="pr-2" style={{color: app?.theme?.colors?.tertiary1 ? app?.theme?.colors?.tertiary1 : '#1b1b1b'}}>
                                                <LoadingOutlined/>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="text-left p-2 text-sm md:text-xs font-bold underline" style={{color: app?.theme?.colors?.tertiary1 ? app?.theme?.colors?.tertiary1 : '#1b1b1b'}}>
                                Your Messages
                            </div>
                            <MessagePreview text={'Intro Chat'} onClick={clickDemo} />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="w-full aspect-[3/2] relative flex flex-col rounded-3xl bg-[#F5F5F5] overflow-hidden cursor-pointer">
                        <div className="w-full aspect-[3/1] border-b-2 bg-cover bg-no-repeat bg-center" style={app?.banner_image?.image ?  {backgroundImage: `url(${app?.banner_image?.image})`} : {backgroundColor:app?.theme?.colors?.tertiary2 ? app?.theme?.colors?.tertiary2 : '#111111'}}></div>
                        <div className="flex absolute left-0 right-0 top-0 bottom-0 justify-center items-center">
                            <div className="flex flex-col gap-2 pt-4">
                                <div className="w-20 md:w-14 m-auto aspect-square rounded-xl border-2 bg-cover bg-no-repeat bg-center" style={app?.avatar?.image ?  {backgroundImage: `url(${app?.avatar?.image})`} : {borderColor:app?.theme?.colors?.tertiary1 ? app?.theme?.colors?.tertiary1 : '#111111'}}></div>
                                <div className="text-xl md:text-lg font-semibold text=[#111111]">
                                    {app?.name}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


function ChatSider() {
    const {isSiderVisible,setIsSiderVisible,app} = useChatContext();

    useEffect(() => {
        if(window.innerWidth > 1000){
            setIsSiderVisible(true);
        }
        else {
            setIsSiderVisible(false);
        }
    },[])

    return(
        window.innerWidth > 1000
        ?
            <Sider
                width={250}
                collapsedWidth={0}
                collapsed={!isSiderVisible}
                trigger={null}
                style={{maxWidth:'500px'}}
                className="shadow-xl"
                collapsible>
                <ChatSiderContent app={app} setIsSiderVisible={setIsSiderVisible} />
            </Sider>
        :
            <ConfigProvider
                theme={{
                    token: {
                        padding:0,
                    },
                }}>
                <Drawer 
                    open={isSiderVisible}
                    onClose={() => setIsSiderVisible(false)}
                    onCancel={() => setIsSiderVisible(false)}
                    closeIcon={null}
                    width={'80%'}
                    height={'100%'}
                    placement="left"
                    closable={true}
                    styles={{
                        "body":{
                            padding:0
                        },
                        "header":{
                            display:'none'
                        }
                    }}
                    >
                    <ChatSiderContent app={app} setIsSiderVisible={setIsSiderVisible} />
                </Drawer>
            </ConfigProvider>
    )
}

export default ChatSider;