import Navbar from "./components/Navbar";

function Error404Page() {
    
    return(
        <div className="w-screen h-full relative bg-white min-h-screen overflow-x-hidden">
            <Navbar />
            <div>
                
            </div>
        </div>
    )
}
export default Error404Page;