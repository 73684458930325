import img1 from '../../img/voice/voice1.webp';
import img2 from '../../img/voice/voice2.webp';
import img3 from '../../img/voice/voice3.webp';
import img4 from '../../img/voice/voice4.webp';
import img5 from '../../img/voice/voice5.webp';

function VisionItemsScroller() {
    
    return(
        <article class={`wrapper`}>
            <div class={`marquee`}>
                <div class="marquee__group">
                    <div className='w-[350px] md:w-[450px]'>
                        <img src={img1} loading="lazy" />
                    </div>
                    <div className='w-[350px] md:w-[450px]'>
                        <img src={img2} loading="lazy" />
                    </div>
                    <div className='w-[350px] md:w-[450px]'>
                        <img src={img3} loading="lazy" />
                    </div>
                    <div className='w-[350px] md:w-[450px]'>
                        <img src={img4} loading="lazy" />
                    </div>
                    <div className='w-[350px] md:w-[450px]'>
                        <img src={img5} loading="lazy" />
                    </div>
                </div>
                <div aria-hidden="true" class="marquee__group">
                    <div className='w-[350px] md:w-[450px]'>
                        <img src={img1} loading="lazy" />
                    </div>
                    <div className='w-[350px] md:w-[450px]'>
                        <img src={img2} loading="lazy" />
                    </div>
                    <div className='w-[350px] md:w-[450px]'>
                        <img src={img3} loading="lazy" />
                    </div>
                    <div className='w-[350px] md:w-[450px]'>
                        <img src={img4} loading="lazy" />
                    </div>
                    <div className='w-[350px] md:w-[450px]'>
                        <img src={img5} loading="lazy" />
                    </div>                    
                </div>
            </div>
        </article>
    )
}

export default VisionItemsScroller;