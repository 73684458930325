import { Carousel, Form, Input, message } from "antd";
import Navbar from "./components/Navbar";
import sq1 from '../../img/square-1.webp';
import sq2 from '../../img/square-2.webp';
import sq3 from '../../img/square-3.webp';
import sq4 from '../../img/square-4.webp';
import sq5 from '../../img/square-5.webp';
import screen4 from '../../img/screen-4.webp';
import { CheckCircleFilled, CloseCircleOutlined, CommentOutlined, FacebookOutlined, LoadingOutlined, TikTokOutlined, TwitchOutlined, TwitterOutlined, UserOutlined, YoutubeOutlined } from "@ant-design/icons";
import FAQCollapse from "../../components/collapse/FAQCollapse";
import Footer from "../../components/footer/Footer";
import CuratedItemsScroller from "../../components/scroller/CuratedItemsScroller";
import NicheCarousel from "../../components/carousel/NicheCarousel";
import WaitlistExtraModal from "../../components/modal/WaitlistExtraModal";
import { useState } from "react";
import EmailServices from "../utils/EmailServices";
import VisionItemsScroller from "../../components/scroller/VisionItemsScroller";

function HomePage() {
    const [isWaitlistExtraModalVisible,setIsWaitlistExtraModalVisible] = useState(false);
    const [email,setEmail] = useState(null);
    const [emailValid,setEmailValid] = useState(false);
    const [loading,setLoading] = useState(false);

    const clickGetStarted = () => {
        const element = document.getElementById("get-started");
        window.scrollTo({top: element.offsetTop - 150,behavior: 'smooth',});
        window.history.pushState(null,null,'#get-started');
    }
    const validateEmail = (email) => {
        var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
        return re.test(email);    
    };
      
    const changeEmail = (value) => {
        setEmail(value);
        setEmailValid(validateEmail(value));
    }
    const addToWaitlist = async () => {
        setLoading(true);
        if(validateEmail(email)){
            const emailResponse = await EmailServices.addToWaitlist({email:email,social:''});
            message.success('Added to Waitlist!');
            setIsWaitlistExtraModalVisible(true);
        }
        else{
            message.info('Must have valid email.');
        }
        setLoading(false);
    }

    function reveal_by_id(id) {
        var reveals = document.querySelectorAll(id);
    
        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 0;
        
            if (elementTop + 50 < windowHeight - elementVisible ) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }
    function reveal(){
        reveal_by_id(".reveal");
        reveal_by_id(".reveal-top");
        reveal_by_id(".reveal-left");
        reveal_by_id(".reveal-right");
    }
    window.addEventListener(
        "scroll",() => reveal(),{ passive: true }
    );
    
    return(
        <div className="w-screen h-full relative bg-white min-h-screen overflow-x-hidden">
            <Navbar onClick={clickGetStarted} />
            <div className="w-screen bg-[#6A0DAD] h-full flex items-start grid grid-cols-1 md:grid-cols-2 pt-[10vh] md:pt-[23vh] pb-[10vh] md:pb-[30vh]">
                <div className="flex items-center justify-center landing-fadein">
                    <div className="h-1/2 w-10/12 md:w-full pl-0 md:pl-16 pt-16 text-[#f5f5f5] text-left flex flex-col gap-4">
                        <div className="text-4xl md:text-5xl font-bold leading-tight md:leading-[3.5rem]">
                            Forget <s>ChatGPT</s>.<br/>
                            Talk to Your Favorite Creators.
                        </div>
                        <div className="text-xl md:text-2xl font-bold leading-tight mb-4">
                            Turn followers into superfans with a personalized chatbot.
                            Connect with your audience in a completely new way.
                        </div>
                        <div className="flex flex-col md:flex-row items-center justify-start gap-2">
                            <div className="p-3 w-full md:w-auto rounded-md bg-white text-base text-[#1f1f1f] font-semibold cursor-pointer">
                                <Input prefix='talkin.bio/' placeholder="yourname" size='large' variant="borderless" className="w-full" />
                            </div>
                            <div onClick={clickGetStarted} className="px-6 py-4 rounded-full bg-[#FF4081] text-base text-white cursor-pointer font-semibold rounded-full">
                                Claim your TalkInBio
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex items-center justify-center landing-fadein">
                    <div className="w-10/12 m-auto pt-[5vh] md:p-0">
                        <Carousel speed={400} arrows autoplay >
                            <div className="w-fit pb-8">
                                <img src={sq1} loading='lazy' onClick={clickGetStarted} className="h-auto md:h-[70vh] md:w-auto rounded-[40px] drop-shadow-xl border-2 border-white m-auto" />
                            </div>
                            <div className="w-fit">
                                <img src={sq2} loading='lazy' onClick={clickGetStarted} className="h-auto md:h-[70vh] rounded-[40px] drop-shadow-xl border-2 border-white m-auto" />
                            </div>
                            <div className="w-fit">
                                <img src={sq3} loading='lazy' onClick={clickGetStarted} className="h-auto md:h-[70vh] rounded-[40px] drop-shadow-xl border-2 border-white m-auto" />
                            </div>
                            <div className="w-fit">
                                <img src={sq4} loading='lazy' onClick={clickGetStarted} className="h-auto md:h-[70vh] rounded-[40px] drop-shadow-xl border-2 border-white m-auto" />
                            </div>
                            <div className="w-fit">
                                <img src={sq5} loading='lazy' onClick={clickGetStarted} className="h-auto md:h-[70vh] rounded-[40px] drop-shadow-xl border-2 border-white m-auto" />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
            <div className="h-auto w-screen bg-[#FF4081] pt-[15vh] pb-[28vh]">
                <div className="w-10/12 m-auto flex flex-col gap-8">
                    <div className="reveal-left text-[#f5f5f5] text-left">
                        <h1 className="text-4xl md:text-5xl font-bold md:font-semibold leading-tight md:leading-[3.5rem]">
                            A New Way to Engage
                        </h1>
                    </div>
                    <div className="w-full reveal">
                        <VisionItemsScroller/>
                    </div>
                    <div className="w-11/12 md:w-10/12 text-[#f5f5f5] text-left flex flex-col gap-6 reveal">
                        <div className="text-2xl font-normal leading-snug">
                            TalkInBio is <strong>transforming how creators engage with their fans</strong> by allowing them to share their stories, latest updates, favorite products and more through conversations that feel<br/> <strong>authentic, immersive, and engaging</strong>.
                        </div>
                        <div className="text-2xl font-normal leading-snug">
                            Connect with your fans through a <strong>Virtual You</strong>.
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-[#1A73E8] h-full pt-[10vh] pb-[20vh]">
                <div className="px-0 md:px-16 grid grid-cols-1 md:grid-cols-2 gap-4 flex items-center">
                    <div className="w-full h-full m-auto relative reveal pt-5 md:pt-16">
                        <div>
                            <img src={screen4} loading='lazy' className="h-[60vh] md:h-[70vh] md:w-auto rounded-3xl drop-shadow-xl border-2 border-white m-auto shadow-[5px_4px_0_0_rgba(0,0,0,0.8)]" />
                        </div>
                        <div className="absolute left-2 md:left-0 md:left-10 top-[30%] md:top-[10vh]">
                            <div className="p-4 bg-white rounded-2xl text-center shadow-[4px_4px_2px_0_rgba(0,0,0,0.8)]">
                                <div className="mb-1">
                                    <h2 className="text-lg font-semibold">Connect Your Socials</h2>
                                </div>
                                <div className="grid grid-cols-5 text-xl">
                                    <div className="text-center text-[#1877F2]">
                                        <FacebookOutlined/>
                                    </div>
                                    <div className="text-center text-[#6441a5]">
                                        <TwitchOutlined/>
                                    </div>
                                    <div className="text-center text-[#1DA1F2]">
                                        <TwitterOutlined/>
                                    </div>
                                    <div className="text-center text-[#111111]">
                                        <TikTokOutlined/>
                                    </div>
                                    <div className="text-center text-[#CD201F]">
                                        <YoutubeOutlined/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="absolute right-2 md:right-0 md:right-10 top-[50%] md:top-[30vh]">
                            <div className="p-4 bg-white border rounded-2xl text-left shadow-[4px_4px_2px_0_rgba(0,0,0,0.8)]" style={{maxWidth:'250px'}}>
                                <div>
                                    <h2 className="text-lg font-semibold">Share Recommendations</h2>
                                </div>
                                <div>
                                    Curate your favorite things and make recommendations to fans like you would with friends.
                                </div>
                            </div>
                        </div>
                        <div className="absolute left-2 md:left-0 md:left-10 bottom-[12%] md:bottom-[10vh]">
                            <div className="p-4 bg-white rounded-2xl text-center shadow-[4px_4px_2px_0_rgba(0,0,0,0.8)]">
                                <div>
                                    <h2 className="text-lg font-semibold">Customize Your Theme</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 text-white w-10/12 text-left reveal-right pt-[5vh] md:pt-0 m-auto md:m-none">
                        <h1 className="text-3xl md:text-5xl font-bold leading-tight md:leading-[3.5rem] mb-0">
                            Create and Customize <br/>
                            your TalkInBio in <br/>
                            minutes
                        </h1>
                        <div className="text-xl font-medium leading-tight mb-2">
                            Connect your Youtube or Website and create personalized experiences for your audience.
                        </div>
                        <div onClick={clickGetStarted} className="px-6 py-4 w-fit rounded-full bg-[#EDEAFF] text-base text-[#1f1f1f] cursor-pointer font-semibold rounded-full">
                            Get started for free
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-[#EDEAFF] h-full pt-[10vh] md:pt-[10vh] pb-[10vh] md:pb-[15vh]">
                <div className="p-0 md:p-16 flex flex-col gap-12 text-[#6A0DAD] text-left w-10/12 md:w-full m-auto">
                    <div className="flex flex-col gap-6 md:gap-12">
                        <h1 className="w-full md:w-4/5 text-4xl md:text-5xl font-bold leading-tight md:leading-[3.5rem] text-left reveal">
                            {window.innerWidth < 1000 ? 'Share Your' : 'Curate'} Recommendations.
                        </h1>
                        <div className="reveal">
                            <h2 className="text-xl md:text-2xl font-normal md:font-medium leading-tight">
                                Share with your Audience like you would with a Close Friend.<br/>
                                Add your favorite products, places, stories, anything you love, and connect and share with your audience.
                            </h2>
                        </div>
                    </div>
                    <div className="flex flex-col gap-8 md:gap-12 reveal">
                        <div>
                            <CuratedItemsScroller />
                        </div>                 
                        <div onClick={clickGetStarted} className="px-6 py-4 w-fit rounded-full bg-[#6A0DAD] text-base text-white cursor-pointer font-semibold rounded-full">
                            Get started for free
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-white h-full py-[10vh]">
                <div className="p-6 md:p-16 flex flex-col gap-8 text-[#FF4081]">
                    <div className="flex flex-col gap-8 text-left reveal-left">
                        <div className="w-full">
                            <h1 className="text-5xl font-bold leading-[3.5rem]">
                                Cater to your Niche
                            </h1>
                        </div>
                        <div>
                            <h2 className="text-2xl font-medium">
                                Craft an experience around your specific niche.<br/>
                                Offer unique services to fit your audience's needs.
                            </h2>
                        </div>
                    </div>
                    <div className="flex flex-col gap-8 reveal">
                        <div>
                            <NicheCarousel onClick={clickGetStarted}/>
                        </div>
                        <div onClick={clickGetStarted} className="w-full text-center">
                            <div className="px-6 py-4 w-fit rounded-full bg-[#FF4081] text-base text-white cursor-pointer font-semibold rounded-full">
                                Get started for free
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-[#e3e6fc] h-full py-[10vh] md:py-[15vh]">
                <div className="p-6 md:p-16 flex flex-col gap-8 md:gap-12 text-[#1A73E8]">
                    <div className="text-left">
                        <div className="w-full mb-4 reveal">
                            <h1 className="text-5xl font-bold leading-tight md:leading-[3.5rem]">Personalize Engagement</h1>
                        </div>
                        <div>
                            <h2 className="text-2xl font-semibold reveal">
                                No more one-size-fits-all.<br/>
                                Unique Personalized Experience for Every Follower.
                            </h2>
                        </div>
                    </div>
                    <div className="flex flex-col gap-12 reveal">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-left w-full text-white">
                            <div className="w-full flex flex-col gap-2 border p-8 bg-[#1A73E8] rounded-3xl">
                                <div className="text-2xl font-bold">
                                    Engage
                                </div>
                                <div className="text-xl font-medium leading-tight pb-2 w-10/12">
                                    Give your followers a unique experience 24/7, even when you're offline. 
                                </div>
                            </div>
                            <div className="w-full flex flex-col gap-2 border p-8 bg-[#1A73E8] rounded-3xl">
                                <div className="text-2xl font-bold">
                                    Monetize
                                </div>
                                <div className="text-xl font-medium leading-tight pb-2">
                                    Promote relevant products, affiliate links, and sponsorships
                                    directly through personalized chatbot recommendations.
                                </div>
                            </div>
                            <div className="w-full flex flex-col gap-2 border p-8 bg-[#1A73E8] rounded-3xl">
                                <div className="text-2xl font-bold">
                                    Learn
                                </div>
                                <div className="text-xl font-medium leading-tight pb-2">
                                    Learn more about your audience!<br/>
                                    See what your audience is interested in. Make 1-on-1 connections with every follower.
                                </div>
                            </div>
                        </div>
                        <div onClick={clickGetStarted} className="w-full text-left">
                            <div className="px-6 py-4 w-fit rounded-full bg-[#1A73E8] text-base text-white cursor-pointer font-semibold rounded-full">
                                Get started for free
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='get-started' className="w-full bg-[#FF4081] h-full py-[10vh]">
                <div className="p-6 md:p-16 flex flex-col gap-12 text-white">
                    <div className="w-10/12 md:w-full text-5xl font-bold text-left md:text-center reveal-right">
                        We are Invite-Only
                    </div>
                    <div className="w-full md:w-1/2 m-auto reveal" style={{maxWidth:'600px'}}>
                        <div className="w-full md:w-fit m-auto flex flex-col md:flex-row items-center gap-2">
                            <div className="w-full p-2 bg-white rounded-lg">
                                <Input 
                                    variant="borderless" 
                                    onChange={(value) => changeEmail(value.target.value)}
                                    prefix={<UserOutlined style={{color:'rgba(0,0,0,0.4)'}} />} 
                                    suffix={email ? emailValid ? <CheckCircleFilled style={{color:'#52c41a',fontSize:'14px'}} /> : <CloseCircleOutlined style={{color:'#f5222d',fontSize:'14px'}} /> : null} 
                                    onPressEnter={addToWaitlist}
                                    className="w-full min-w-[300px]" 
                                    placeholder="Your email account" 
                                    size='large' />
                            </div>
                            <div className="w-full md:w-fit">
                                <div onClick={addToWaitlist} className="px-4 py-3 w-full md:w-fit text-nowrap rounded-full bg-white text-base text-[#FF4081] m-none md:m-auto cursor-pointer font-semibold rounded-full">
                                    {loading && <LoadingOutlined className="mr-1"/>} Request Access
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full bg-[#333333] h-full py-[10vh]">
                <div className="p-4 md:p-16 flex flex-col gap-12 text-white">
                    <div className="w-full text-5xl font-bold text-center reveal">
                        Questions?
                    </div>
                    <div className="w-11/12 md:w-10/12 m-auto reveal" style={{maxWidth:'800px'}}>
                        <FAQCollapse/>
                    </div>
                </div>
            </div>
            <div className="w-full bg-[#6A0DAD] h-full pt-[15vh]">
                <div className="p-4 md:p-16 flex flex-col gap-4 md:gap-8 text-white">
                    <div className="flex flex-col gap-12 mb-[20vh] reveal">
                        <div className="w-full text-5xl text-[#EDEAFF] font-bold text-center">
                            Talk with your Audience
                        </div>
                        <div className="flex flex-col md:flex-row items-center gap-2 w-full md:w-fit m-auto reveal">
                            <div className="p-3 w-full md:w-fit rounded-md bg-white text-[#1f1f1f] font-semibold cursor-pointer">
                                <Input prefix='talkin.bio/' placeholder="yourname" size='large' variant="borderless" className="w-full" />
                            </div>
                            <div onClick={clickGetStarted} className="px-6 py-3 md:py-4 rounded-full bg-[#FF4081] text-center w-full md:w-fit text-base text-white cursor-pointer font-semibold rounded-full">
                                Claim your TalkInBio
                            </div>
                        </div>
                    </div>
                    <div className="w-11/12 m-auto rounded-3xl bg-white p-12 reveal">
                        <Footer/>
                    </div>
                    <div onClick={clickGetStarted} className="w-11/12 m-auto bg-[#EDEAFF] p-10 md:reveal cursor-pointer" style={{borderRadius:'60px'}}>
                        <div className="font-medium text-5xl md:text-[60px]" style={{color:'rgba(0,0,0,0.4)'}}>
                            TalkInBio <CommentOutlined/>
                        </div>
                    </div>
                </div>
            </div>
            <WaitlistExtraModal email={email} isWaitlistExtraModalVisible={isWaitlistExtraModalVisible} setIsWaitlistExtraModalVisible={setIsWaitlistExtraModalVisible} />
        </div>
    )
}
export default HomePage;