import { useEffect, useState } from "react";
import { ConfigProvider, Input, message } from "antd";
import { ArrowUpOutlined, BulbOutlined, CommentOutlined, LoadingOutlined, SendOutlined, SignatureOutlined, UpCircleOutlined } from "@ant-design/icons";
import ChatBubble from "./ChatBubble";
import LoadingBubble from "./LoadingBubble";
import ChatServices from "../../../utils/ChatServices";
import { useChatContext } from "../context/ChatContext";

function ChatUI() { 
    let timeElapsed = 0;
    let timerId;
    const {app,messages,setMessages} = useChatContext();
    const [input,setInput] = useState('');
    const [inputActive,setInputActive] = useState(false);
    const [loading,setLoading]= useState(false);

    const clickDemo = () => {
        message.info('This feature is not available in demo.');
    }
    const initialSetup = () => {
        setMessages([]);
        setLoading(true);
        if(app?.parameters){
            setLoading(false);
        }
        if(app?.parameters?.intro){
            setMessages([{text:app?.parameters?.intro, sender:false,timestamp:`${new Date().getHours()}:${new Date().getMinutes() >= 10 ? new Date().getMinutes() : `0${new Date().getMinutes()}`}`}]);
        }
    }
    const clickSuggestion = async (value) => {
        await setLoading(true);
        await sendMessage(value);
        await setLoading(false);
    }
    const clickEnter = (e) => {
        if(e.ctrlKey || e.shiftKey || window.innerWidth < 1000){
            e.preventDefault();
            setInput(input + "\n");
        } else {
            onMessage();
        }
    }
    const sendMessage = async (inputValue) => {
        var ms_div = document.getElementById('messager');
        document.getElementById('suggestions').scrollTo(0,0);
        setInput('');
        setMessages(prev => [...prev,{text:inputValue,sender:true,timestamp:`${new Date().getHours()}:${new Date().getMinutes() >= 10 ? new Date().getMinutes() : `0${new Date().getMinutes()}`}`}]);
        setTimeout(() => {
            ms_div.scrollTo({top: ms_div.scrollHeight,behavior: "smooth",});
        },[500])
        const searchParams = {
            'query':`${app?.parameters?.['hidden-prompt']}\n\nUser Message: ${inputValue}`,
            'app_id':app?.ov_id
        }
        let searchResponse = await ChatServices.queryChatByApp({values:searchParams});
        setMessages(prev => [...prev,{text:searchResponse.data?.response,sources:searchResponse.data?.sources,sender:false,timestamp:`${new Date().getHours()}:${new Date().getMinutes() >= 10 ? new Date().getMinutes() : `0${new Date().getMinutes()}`}`}]);
        setTimeout(() => {
            ms_div.scrollTo({top: ms_div.scrollTop + 300,behavior: "smooth",});
        },[500]);
        return 'done'
    }
    const onMessage = async () => {
        // When Sends Message
        timeElapsed = 0;
        if(input != ''){
            clearInterval(timerId);
            timerId= setInterval(() => {
                timeElapsed += 1
            }, 1000);
            setLoading(true);
            await sendMessage(input);
        }
        else{
            message.info('Write a message')
        }
        setLoading(false);
    }

    useEffect(() => {
        initialSetup();
    },[app]);

    return(
        <div className="h-full w-full relative">
            <div id='messager' className="absolute top-0 left-0 right-0 bottom-0 overflow-y-auto scroll-smooth">
                <div className="w-full md:w-4/5 max-w-[500px] h-auto min-h-screen p-0 m-auto relative shadow-sm">
                    <div className="w-full h-auto min-h-screen flex flex-col gap-2 relative pb-36 rounded-none md:rounded-3xl overflow-hidden" style={{backgroundColor: window.innerWidth > 1000 ? app?.theme?.colors?.primary ? `${app?.theme?.colors?.primary}F0` : 'white' : null}}>
                        {
                            !app &&
                                <div className="text-center text-lg p-4"><LoadingOutlined/></div>
                        }
                        <div className="w-full aspect-[2/1] relative">
                            <div className="w-full aspect-[4/1] border-b-2 bg-cover bg-no-repeat bg-center" style={app?.banner_image?.image ?  {backgroundImage: `url(${app?.banner_image?.image})`} : {backgroundColor:app?.theme?.colors?.tertiary2 ? app?.theme?.colors?.tertiary2 : '#111111'}}></div>
                            <div className="flex absolute left-0 right-0 top-0 bottom-0 justify-center items-center">
                                <div className="flex flex-col gap-2 pt-8 w-full">
                                    <div className="w-1/5 m-auto aspect-square mb-2 rounded-full border-4 bg-cover bg-no-repeat bg-center" style={app?.avatar?.image ?  {backgroundImage: `url(${app?.avatar?.image})`} : {borderColor:app?.theme?.colors?.tertiary1 ? app?.theme?.colors?.tertiary1 : '#111111'}}></div>
                                    <div className="text-xl font-semibold" style={{color:app?.theme?.colors?.tertiary2 ? app?.theme?.colors?.tertiary2 : '#111111'}}>
                                        {app?.name}
                                    </div>
                                    <div onClick={clickDemo} className="text-sm cursor-pointer" style={{color:app?.theme?.colors?.tertiary2 ? app?.theme?.colors?.tertiary2 : '#111111'}}>
                                        View Profile
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            messages && messages.length > 0 &&
                                messages.map((value,index) => 
                                    <div id={`message-${index}`} key={`message-${index}`} className="px-4">
                                        <ChatBubble text={value['text']} sender={value['sender']} timestamp={value?.timestamp} />
                                    </div>
                                )
                        }
                        {
                            app && loading &&
                                <div className="w-full flex items-center pt-4 px-2" style={{color:app?.theme?.colors?.tertiary2 ? app?.theme?.colors?.tertiary2 : '#111111'}}>
                                    <div className="flex-1">
                                        <LoadingBubble/>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0 w-full">
                <div className="w-full md:w-4/5 max-w-[500px] p-0 m-auto p-2 md:p-4 pt-1 md:pt-1 pb-2 flex flex-col gap-1 relative rounded-none md:rounded-b-3xl overflow-hidden" style={{backgroundColor: app?.theme?.colors?.primary ? app?.theme?.colors?.primary : null}}>
                    {
                        app?.agent_temp && app?.agent_temp?.suggestions && app?.agent_temp?.suggestions.length > 0 &&
                            <div id='suggestions' className="p-1 pt-0.5 w-full overflow-x-scroll flex items-center gap-1 hide-scrollbar">
                                <div className="text-lg" style={{color: app?.theme?.colors?.tertiary2 ? app?.theme?.colors?.tertiary2 : '#111111'}}>
                                    <SignatureOutlined/>
                                </div>
                                {
                                    app?.agent_temp?.suggestions.map((value,index) =>
                                        <div onClick={() => clickSuggestion(value)} id={`suggestion-${index}`} className="p-2 md:py-1 pr-3 border rounded-xl text-left w-full text-nowrap cursor-pointer" style={{color: app?.theme?.colors?.tertiary2 ? app?.theme?.colors?.tertiary2 : '#F5F5F5',borderColor:app?.theme?.colors?.tertiary2 ? app?.theme?.colors?.tertiary2 : '#F5F5F5'}}>
                                            {value}
                                        </div>                                    
                                    )
                                }
                            </div>
                    }
                    <div className="flex items-end rounded-3xl py-1 md:py-0 px-1 duration-300 shadow-md" style={{backgroundColor: app?.theme?.colors?.tertiary2 ? inputActive ? `${app?.theme?.colors?.tertiary2}F5` : `${app?.theme?.colors?.tertiary2}80` : null,borderColor: app?.theme?.colors?.tertiary2 ? app?.theme?.colors?.tertiary2 : 'rgba(0,0,0,0.8)'}}>
                        <div className="p-2 text-lg md:text-base">
                            <div style={{color: app?.theme?.colors?.secondary ? app?.theme?.colors?.secondary : '#111111', opacity:input ? 1 : 0.5}}><CommentOutlined/></div>
                        </div>
                        <div className="flex-1 pb-1 md:pb-0.5">
                            <ConfigProvider
                                theme={{
                                    token: {
                                        "colorTextPlaceholder":app?.theme?.colors?.primary ? `${app?.theme?.colors?.primary}40` : 'rgba(0,0,0,0.4)'
                                    },
                                }}
                                >
                                <Input.TextArea
                                    size='large'
                                    placeholder={`Message ${app?.name}`}
                                    value={input}
                                    onChange={(value) => setInput(value.target.value)}
                                    onPressEnter={clickEnter}
                                    onFocus={() => setInputActive(true)}
                                    onBlur={() => setInputActive(false)}
                                    className="text-lg md:text-base w-full pl-0"
                                    style={{lineHeight:window.innerWidth > 1000 ? 1.5 : 1.3,color: app?.theme?.colors?.primary ? app?.theme?.colors?.primary : '#111111'}}
                                    variant="borderless"
                                    disabled={loading}
                                    autoSize={{maxRows:6}}
                                    autoFocus
                                    />
                            </ConfigProvider>
                        </div>
                        <div className="p-2">
                            <div onClick={onMessage} 
                                className="text-lg md:text-base rounded-full px-1 aspect-square flex items-center justify-center cursor-pointer hover:brightness-125 duration-150" 
                                style={input ? {backgroundColor: app?.theme?.colors?.secondary ? app?.theme?.colors?.secondary : '#111111', color: app?.theme?.colors?.tertiary2 ? app?.theme?.colors?.tertiary2 : '#F5F5F5'} : {color: app?.theme?.colors?.secondary ? app?.theme?.colors?.secondary : '#111111'}}>
                                <ArrowUpOutlined/>
                            </div>
                        </div>
                    </div>
                    <div className="text-xs" style={{color: app?.theme?.colors?.tertiary2 ? app?.theme?.colors?.tertiary2 : 'white'}}>
                        Check important details.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChatUI;