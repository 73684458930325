import { useChatContext } from "../context/ChatContext"

function LoadingBubble() {
    const {theme} = useChatContext();

    return(
        <div className="loading-bubble">
            <div className="dot" style={{backgroundColor: theme?.colors?.tertiary2 ? theme?.colors?.tertiary2 : '#f5f5f5'}}></div>
            <div className="dot" style={{backgroundColor: theme?.colors?.tertiary2 ? theme?.colors?.tertiary2 : '#f5f5f5'}}></div>
            <div className="dot" style={{backgroundColor: theme?.colors?.tertiary2 ? theme?.colors?.tertiary2 : '#f5f5f5'}}></div>
        </div>
    )
}

export default LoadingBubble