import img1 from '../../img/curate/curate-1.webp';
import img2 from '../../img/curate/curate-2.webp';
import img3 from '../../img/curate/curate-3.webp';
import img4 from '../../img/curate/curate-4.webp';
import img5 from '../../img/curate/curate-5.webp';
import img6 from '../../img/curate/curate-6.webp';
import img7 from '../../img/curate/curate-7.webp';
import img8 from '../../img/curate/curate-8.webp';
import img9 from '../../img/curate/curate-9.webp';
import img10 from '../../img/curate/curate-10.webp';
import img11 from '../../img/curate/curate-11.webp';

function CuratedItemsScroller() {
    
    return(
        <article class={`wrapper`}>
            <div class={`marquee`}>
                <div class="marquee__group">
                    <div className='w-[250px]'>
                        <img src={img1} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img2} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img3} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img4} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img5} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img6} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img7} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img8} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img9} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img10} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img11} loading='lazy' />
                    </div>
                </div>
                <div aria-hidden="true" class="marquee__group">
                    <div className='w-[250px]'>
                        <img src={img1} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img2} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img3} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img4} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img5} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img6} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img7} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img8} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img9} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img10} loading='lazy' />
                    </div>
                    <div className='w-[250px]'>
                        <img src={img11} loading='lazy' />
                    </div>
                </div>
            </div>
        </article>
    )
}

export default CuratedItemsScroller;