import { InfoCircleOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Input, message, Modal, Popover } from "antd";
import { useEffect, useState } from "react";
import EmailServices from "../../containers/utils/EmailServices";


function WaitlistExtraModal({email,isWaitlistExtraModalVisible,setIsWaitlistExtraModalVisible}) {
    const [social,setSocial] = useState(null);
    const [loading,setLoading] = useState(false);

    const clickEnter = async () => {
        setLoading(true);
        const emailResponse = await EmailServices.addToWaitlist({email:email,social:social});
        message.success('Thanks for Adding Your Socials!');
        setIsWaitlistExtraModalVisible(false);
        setLoading(false);
    }
    useEffect(() => {
        if(document.getElementById('social-input')){
            document.getElementById('social-input').focus();
        }
    },[isWaitlistExtraModalVisible]);

    return(
        <Modal 
            open={isWaitlistExtraModalVisible}
            onCancel={() => setIsWaitlistExtraModalVisible(false)}
            centered={true}
            style={{borderRadius:'15px',overflow:'hidden',marginBottom:'5vh'}}
            destroyOnClose
            footer={null}
            >
            <div className="p-6 pb-8 text-[#6A0DAD] flex flex-col gap-3">
                <div>
                    <div className="mb-0.5">
                        <h2 className="text-3xl font-bold">
                            We got your Request!
                        </h2>
                    </div>
                    <div className="p-1" style={{color:'rgba(0,0,0,0.6)'}}>
                        <h4 className="text-sm font-normal">
                            Add Your Social Media Account. (Optional) 
                        </h4>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <div>
                        <div className="flex items-center gap-1">
                            Your Main Social 
                            <Popover 
                                content={
                                    <div style={{maxWidth:'250px'}}>
                                        <div>
                                            <h3 className="font-semibold">Skip the Line!</h3>
                                        </div>
                                        Adding Your Account Brings you to the Front of the Waitlist.
                                    </div>
                                }>
                                <InfoCircleOutlined className="cursor-pointer text-xs"/>
                            </Popover>
                        </div>
                        <Input 
                            id='social-input'
                            className="w-full rounded-lg" 
                            prefix={<UserOutlined style={{color:'rgba(0,0,0,0.4)'}}/>}
                            onChange={(value) => setSocial(value.target.value)}
                            size="large"
                            placeholder="Enter Your Main Social Media Channel"
                            autoFocus />
                    </div>
                    <div className="mb-1">
                        <div>
                            Your Email (*)
                        </div>
                        <Input 
                            className="w-full rounded-lg" 
                            prefix={<MailOutlined/>}
                            size="large"
                            value={email}
                            disabled
                            autoFocus />
                    </div>
                    <div className="flex flex-col w-full gap-1">
                        <Button type="primary" loading={loading} size="large" onClick={clickEnter} className="bg-[#6A0DAD] rounded-lg w-full font-bold">
                            Add My Social
                        </Button>
                        <Button type="text" onClick={() => setIsWaitlistExtraModalVisible(false)} className="rounded-lg w-full font-bold">
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default WaitlistExtraModal;