import { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "../../../utils/useLocalStorage";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
    const [isSiderVisible,setIsSiderVisible] = useLocalStorage('siderToggle',true);
    const [activeTab,setActiveTab] = useLocalStorage('activeTab','');
    const [app,setApp] = useLocalStorage('app',null);
    const [messages,setMessages] = useLocalStorage('messages',[]);
    const [isCreateAppModalVisible,setIsCreateAppModalVisible] = useLocalStorage('createAppModal',true);
    const theme = app?.theme;
    
    const value = useMemo(
        () => ({
            isSiderVisible,setIsSiderVisible,
            activeTab,setActiveTab,
            app,setApp,theme,
            messages,setMessages,
            isCreateAppModalVisible,setIsCreateAppModalVisible,
        }),[isSiderVisible,app,messages,isCreateAppModalVisible,activeTab]
    );

    return(
        <ChatContext.Provider value={value}>
            {children}
        </ChatContext.Provider>
    );
};

export const useChatContext = () => {
    return useContext(ChatContext);
};