import { ClockCircleOutlined } from "@ant-design/icons";
import { useChatContext } from "../context/ChatContext";
import Linkify from 'react-linkify';

function ChatBubble({text,sender,timestamp=false}) {
    const {theme} = useChatContext();

    return(
        <div className={`w-full`}>
            <div className={`flex ${sender ? 'justify-end' : 'justify-start'}`}>
                <div className="p-2.5 px-4 text-left rounded-2xl text-lg md:text-base w-fit leading-snug md:leading-tight whitespace-pre-line text-ellipsis shadow-md" style={{backgroundColor: theme?.colors?.secondary ? theme?.colors?.secondary : '#F5F5F5', color: theme?.colors?.tertiary1 ? theme?.colors?.tertiary1 : '#111111', maxWidth:'80%'}}>
                    <Linkify  componentDecorator={(decoratedHref, decoratedText, key) => ( <a target="blank" className='underline' href={decoratedHref} key={key}> {decoratedText} </a> )} >
                        {text}
                    </Linkify>
                </div>
            </div>
            {
                timestamp &&
                    <div className={`text-left px-3 pt-0.5 text-[10px] opacity-70 ${sender ? 'float-right' : 'float-left'}`} style={{color: theme?.colors?.tertiary2 ? theme?.colors?.tertiary2 : '#F5F5F5', maxWidth:'80%'}}>
                        <ClockCircleOutlined/> {timestamp}
                    </div>
            }
        </div>
    )
}

export default ChatBubble;