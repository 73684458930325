import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useChatContext } from "../components/context/ChatContext";
import logo from '../../../img/logo.png';
import { LoadingOutlined, MenuOutlined, UserOutlined } from "@ant-design/icons";
import AppServices from "../utils/AppServices";
import { Layout, message } from "antd";
import { Helmet } from "react-helmet-async";
import ChatUI from "../components/chat/ChatUI";
import ChatSider from "../components/sider/ChatSider";

const { Content } = Layout;

function ChatPage() {
    const {username} = useParams();
    const {app,setApp,isSiderVisible,setIsSiderVisible} = useChatContext();

    const getApp = async () => {
        if(username[0] !== '@'){
            window.location.href='/';
        }
        else{
            const appResponse = await AppServices.getApp({path:username});
            if(appResponse?.status == 'success' && appResponse?.data?.sk){
                setApp(appResponse.data);
                const ovAppResponse = await AppServices.getOvApp({sk:appResponse.data.sk});
                setApp(prev => {return{...ovAppResponse.data,'ov_id':ovAppResponse.data.id,...prev}});
            }
            if(appResponse?.data?.data == "Path Doesn't Exist"){
                message.info(`This Chat does not exist.`);
                setTimeout(() => {
                    window.location.href='/';
                },[1500]);
            }
        }
    }

    const clickDemo = () => {
        message.info('This feature is not available in demo.');
    }

    useEffect(() => {
        getApp();
    },[])

    return(
        app
        ?
            <Layout className="fixed top-0 left-0 right-0 bottom-0">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{`${app?.name} App`}</title>
                    <meta property="og:title" content={`${app?.name} App`}/>
                    <meta name="description" content={`${app?.name} - ${app?.description} Create amazing things and turn project ideas to funcitoning products in minutes with OnlyVectors.`} />
                </Helmet>
                <Layout className="w-full h-full bg-cover bg-center bg-no-repeat relative" style={app?.theme?.background_image ? {backgroundImage:`url("${app?.theme?.background_image}")`} : {}}>
                    <ChatSider/>
                    <Content className="w-full h-full" style={{backgroundColor: app?.theme?.background_image ? app?.theme?.colors?.primary ? `${app?.theme?.colors?.primary}F5` : 'rgba(255,255,255,0.9)' : app?.theme?.colors?.primary ? app?.theme?.colors?.primary : 'white'}}>
                        <div className={`fixed top-0 right-0 left-0 p-2 flex items-center justify-center z-10 visible ${isSiderVisible ? 'md:hidden' : ''}`}>
                            <div className="flex-1">
                                <div onClick={() => setIsSiderVisible(prev => !prev)} className="text-xl md:text-lg w-fit rounded-lg aspect-square px-2 border-2 flex items-center justify-center" style={{borderColor: app?.theme?.colors?.tertiary2 ? app?.theme?.colors?.tertiary2 : '#1b1b1b',color: app?.theme?.colors?.tertiary2 ? app?.theme?.colors?.tertiary2 : '#1b1b1b'}}>
                                    <MenuOutlined />
                                </div>
                            </div>
                            <div>
                                <div onClick={clickDemo} className="text-xl w-fit rounded-lg aspect-square px-2 border-2 flex items-center justify-center" style={{borderColor: app?.theme?.colors?.tertiary2 ? app?.theme?.colors?.tertiary2 : '#1b1b1b',color: app?.theme?.colors?.tertiary2 ? app?.theme?.colors?.tertiary2 : '#1b1b1b'}}>
                                    <UserOutlined />
                                </div>
                            </div>
                        </div>
                        <ChatUI />
                    </Content>
                </Layout>
            </Layout>
        :
            <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-white">
                <div className="pb-8">
                    <img src={logo} className="w-20" />
                    <div>
                        <LoadingOutlined />
                    </div>
                </div>
            </div>
    )
}

export default ChatPage;