import axios from "axios";

const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/${process.env.REACT_APP_API_KEY}`;


class ChatServices {

    getApp({values}){

        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/application`,
            data: values
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }

    queryChatByApp({values}){

        const configuration = {
            credentials: 'include',
            method: "post",
            url: API_URL + `/query/chat`,
            data: values
        };

        return axios(configuration)
        .then(response => {
            return response.data
        })
        .catch((error) => {
            return error.response
            error = new Error();
        });
    }
}
export default new ChatServices();