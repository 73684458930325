import { Carousel } from "antd";
import NicheFlipCard from "../../containers/landing/components/NicheFlipCard";
import fitnessImage from "../../img/niche/fitness.webp";
import beautyImage from "../../img/niche/beauty.webp";
import travelImage from "../../img/niche/travel.webp";
import parentingImage from "../../img/niche/parenting.webp";
import financeImage from "../../img/niche/finance.webp";
import shoppingImage from "../../img/niche/shopping.webp";
import petsImage from "../../img/niche/pets.webp";
import lifestyleImage from "../../img/niche/lifestyle.webp";
import foodImage from "../../img/niche/food.webp";
import comedyImage from "../../img/niche/comedy.webp";

function NicheCarousel({onClick}) {
    return(
        <Carousel autoplaySpeed={5000} className="w-full pb-4" autoplay arrows>
            <div>
                <div className="grid grid-cols-2 md:grid-cols-5 gap-4 flex w-full">
                    <div className="rounded-lg aspect-[2/3] cursor-pointer">
                        <NicheFlipCard 
                            name={'Fitness'} image={fitnessImage} 
                            ideas={['Custom Workout','Meal Plans','Motivation','Advice']}
                            onClick={onClick} />
                    </div>
                    <div className="rounded-lg aspect-[2/3] cursor-pointer">
                        <NicheFlipCard 
                            name={'Beauty'} image={beautyImage} 
                            ideas={['Product Recs','Beauty Tips','Daily Routine',"What's new?"]}
                            onClick={onClick} />
                    </div>
                    <div className="rounded-lg aspect-[2/3] cursor-pointer">
                        <NicheFlipCard 
                            name={'Travel'} image={travelImage} 
                            ideas={['Places to go','Food to eat','Travel Hacks','Your Stories']}
                            onClick={onClick} />
                    </div>
                    <div className="rounded-lg aspect-[2/3] cursor-pointer">
                        <NicheFlipCard 
                            name={'Lifestyle'} image={lifestyleImage} 
                            ideas={['A day in the life','Life Advice','Just chat',"Anything"]}
                            onClick={onClick} />
                    </div>
                    <div className="rounded-lg aspect-[2/3] cursor-pointer no-mobile">
                        <NicheFlipCard 
                            name={'Comedy'} image={comedyImage} 
                            ideas={['Tell a Joke','Banter','Roast','Funny Ideas']}
                            onClick={onClick} />
                    </div>
                </div>
            </div>
            <div>
                <div className="grid grid-cols-2 md:grid-cols-5 gap-4 flex">
                    <div className="rounded-lg aspect-[2/3] cursor-pointer">
                        <NicheFlipCard 
                            name={'Food'} image={foodImage} 
                            ideas={['Food Recs','Restaurant Recs','Recipes','Your Favorites']}
                            onClick={onClick} />
                    </div>
                    <div className="rounded-lg aspect-[2/3] cursor-pointer">
                        <NicheFlipCard 
                            name={'Parenting'} image={parentingImage} 
                            ideas={['Tips and Tricks','Resources','Product Recs','Just listen']}
                            onClick={onClick} />
                    </div>
                    <div className="rounded-lg aspect-[2/3] cursor-pointer">
                        <NicheFlipCard 
                            name={'Shopping'} image={shoppingImage} 
                            ideas={['Product Recs','Shopping Inspo','Deals & Promos','Your Shop']}
                            onClick={onClick} />
                    </div>
                    <div className="rounded-lg aspect-[2/3] cursor-pointer">
                        <NicheFlipCard 
                            name={'Pets'} image={petsImage} 
                            ideas={['Pet Ideas','Pet Advice','Product Recs','Routines']}
                            onClick={onClick} />
                    </div>
                    <div className="rounded-lg aspect-[2/3] cursor-pointer no-mobile">
                        <NicheFlipCard 
                            name={'Finance'} image={financeImage} 
                            ideas={['Tips & Advice','Q&A',"What's New",'Consult']}
                            onClick={onClick} />
                    </div>
                </div>
            </div>
        </Carousel>
    )
}

export default NicheCarousel;